<template>
  <div class="demo">
    <hs-datepicker
      :placeholder="'MM/DD/YYYY'"
      :start-calendar-from="'2021-09-30'"
      :available-dates="availableDates"
      v-model="testDate"
    />
    <hs-button>Default</hs-button>
    <hs-button :size="'md'">Default Meddium</hs-button>
    <hs-button :size="'sm'">Default Small</hs-button>
    <hs-button :variant="'red'">Red</hs-button>
    <hs-button :variant="'secondary'">Secondary</hs-button>
    <hs-checkbox v-model="checkboxes.values" :value="'one'">One</hs-checkbox>
    <hs-checkbox v-model="checkboxes.values" :value="'two'">Two</hs-checkbox>
    <hs-checkbox v-model="checkboxes.single" :type="'red'">Single red</hs-checkbox>
    <hs-radio
      v-for="radio in radios.radios"
      :key="radio.value"
      :name="radios.name"
      :value="radio.value"
      :type="radio.type"
      :checked="radio.value === radios.value"
      @change="handleRadioChange"
      >{{ radio.value.charAt(0).toUpperCase() + radio.value.slice(1) }}</hs-radio
    >
    <hs-switch v-model="switchValue">Switcher</hs-switch>
    <hs-input v-model="defaultInputValue" :placeholder="'Default input placeholder'" />
    <hs-input
      v-model="bottomBorderInputValue"
      :placeholder="'Bottom border input placeholder'"
      :type="'bottom-border'"
    />
    <!-- popup BEGIN -->
    <hs-button :size="'sm'" @click="showPopup = true">Show popup</hs-button>
    <hs-popup
      v-if="showPopup"
      aria-labelledby="demo-modal-titile"
      aria-describedby="demo-modal-desc"
      confirm-button-text="Remove Device"
      decline-button-text="Cancel"
      @hide="showPopup = false"
      @confirm="confirm"
    >
      <h1 class="hs-popup__title" id="demo-modal-titile">Popup title</h1>
      <div class="hs-popup__description" id="demo-modal-desc">
        <p>Popup description</p>
      </div>
    </hs-popup>
    <!-- popup END -->
    <GetDirections from="Cupertino" to="San+Francisco" />
  </div>
</template>

<script>
import GetDirections from '@/components/shared/GetDirections';
export default {
  components: {
    GetDirections,
  },
  data() {
    return {
      testDate: '',
      availableDates: [
        '2021-10-01',
        '2021-10-03',
        '2021-10-10',
        '2021-10-15',
        '2021-10-16',
        '2021-10-20',
        '2021-10-23',
        '2021-10-31',
      ],
      checkboxes: {
        values: [],
        single: false,
      },
      radios: {
        value: 'one',
        name: 'test',
        radios: [{value: 'one'}, {value: 'two'}, {value: 'three', type: 'red'}],
      },
      switchValue: false,
      defaultInputValue: '',
      bottomBorderInputValue: '',
      showPopup: false,
    };
  },
  methods: {
    confirm() {
      alert('Removed');
      this.showPopup = false;
    },
    handleRadioChange(obj) {
      this.radios.value = obj.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.demo {
  padding: 25px;
  background-color: $white;
  > * {
    margin-bottom: 10px;
  }
}
</style>
